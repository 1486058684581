<template>
  <div class="">
    <div class="form-row">
      <DetailOrderPayableInputOrderPayable
        ref="payable"
        class="col"
        :docType="docType"
        :templateType="templateType"
        :contactId="contactId"
        @change="setFormdata">
      </DetailOrderPayableInputOrderPayable>

      <div class="col-auto">
        <button class="btn btn-success" @click="createOrder">
          <fa icon="plus"></fa>
        </button>
      </div>
    </div>

    <div
      class="form-row"
      v-for="(form, index) in formData"
      :key="index">
      <sgv-input-text
        disabled
        class="col-8 col-sm-6"
        :value="`${form.account.code} (${form.account.name})`">
      </sgv-input-text>

      <sgv-input-currency
        class="col-4 col-sm-6"
        v-model="form.price"
        :validations="[
          {text: 'required!', value: $v.formData.$each[index].price.$dirty && $v.formData.$each[index].price.$error}
        ]">
      </sgv-input-currency>
    </div>
  </div>
</template>

<script>
import DetailOrderPayableInputOrderPayable from './DetailOrderPayableInputOrderPayable.vue'
import { required } from 'vuelidate/lib/validators'
import { CREATE_ORDER_PAYABLE } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      formType: 'item',
      formData: []
    }
  },
  validations () {
    const formData = {
      $each: {
        orderId: { required },
        price: {
          required,
          minValue: (value) => value > 0
        }
      }
    }
    return {formData}
  },
  methods: {
    setFormdata (data) {
      if (!data) {
        this.formData = []
      } else {
        this.formData = data.orders.map(v => {
          return {
            orderId: v.id,
            account: v.account,
            price: v.remaining
          }
        })
      }
    },
    serializeInput (input) {
      return input.map(v => {
        return {
          price: v.price,
          orderId: v.orderId
        }
      })
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_ORDER_PAYABLE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(res => {
        this.$refs.payable.resetForm()
        this.$emit('orderCreated', res.data.createOrder)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    }
  },
  components: {
    DetailOrderPayableInputOrderPayable
  }
}
</script>

<style lang="css" scoped>
</style>
