<template>
  <sgv-table
    :items="orders"
    :headers="headers"
    :filter.sync="filter"
    :toolbars="toolbars"
    :options.sync="options">

    <template slot="option" v-if="options.toolbar === 'itemData'">
      <DetailOrderPayableInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        @orderCreated="orderList">
      </DetailOrderPayableInput>

      <DetailOrderExpenseInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs"
        :orders="orders"
        @orderCreated="orderList">
      </DetailOrderExpenseInput>

      <DetailOrderWithholdingPriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs"
        :orders="orders"
        @orderCreated="orderList">
      </DetailOrderWithholdingPriceInput>

      <DetailOrderChangeErrorInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs"
        :orders="orders"
        @orderCreated="orderList">
      </DetailOrderChangeErrorInput>

      <DetailOrderPaymentInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs"
        :orders="orders"
        @orderCreated="orderList">
      </DetailOrderPaymentInput>
    </template>

    <template slot-scope="row">
      <DetailOrderSubItem
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :row="row"
        :canDestroy="!formData.approvedAt"
        v-model="selectedRows"
        @updated="orderList">
      </DetailOrderSubItem>
    </template>
  </sgv-table>
</template>

<script>
import { round } from 'lodash'
import DetailOrderPayableInput from './DetailOrderPayableInput.vue'
import DetailOrderExpenseInput from './DetailOrderExpenseInput.vue'
import DetailOrderWithholdingPriceInput from './DetailOrderWithholdingPriceInput.vue'
import DetailOrderPaymentInput from './DetailOrderPaymentInput.vue'
import DetailOrderChangeErrorInput from './DetailOrderChangeErrorInput.vue'
import DetailOrderSubItem from './DetailOrderSubItem.vue'
import {
  LIST_ORDER,
  WATCH_DOC_UPDATED
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    configs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
      selectedRows: [],
    }
  },
  computed: {
    headers () {
      return [
        {text: 'id', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      if (this.formData.approvedAt) return []

      let arr = []
      if (!this.formData.closedAt && this.docId > 0) {
        arr.push({value: 'itemData', icon: 'plus', class: 'text-success'})
      }
      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    ledgersSum () {
      return this.orders.reduce((t,v) => {
        const x = t + v.ledgers.reduce((sub, ledger) => {
          return sub + Number(ledger.amount)
        }, 0)
        return round(x, 2)
      }, 0)
    },
  },
  apollo: {
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.orderList()
        }
      },
    },
  },
  methods: {
    orderList () {
      this.$apollo.query({
        query: LIST_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.orders = res.data.orders
      })
      .catch(() => {
        this.orders = []
      })
    },
  },
  watch: {
    'formData.approvedAt': {
      handler () {
        this.options.toolbar = null
      }
    }
  },
  created () {
    this.orderList()
  },
  components: {
    DetailOrderPayableInput,
    DetailOrderWithholdingPriceInput,
    DetailOrderPaymentInput,
    DetailOrderSubItem,
    DetailOrderExpenseInput,
    DetailOrderChangeErrorInput
  }
}
</script>

<style lang="css">
</style>
