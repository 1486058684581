<template>
  <sgv-input-autocomplete
    ref="dropdown"
    :search.sync="search"
    :display.sync="display"
    :options="options"
    @input="emitSelected"
    :validations="validations"
    v-bind="$attrs">

    <template slot-scope="option">
      {{option.code}} ({{option.name}})
      <small class="" v-if="option.dueAt || option.remark">
        <br>
        {{option.dueAt | date}}
        <span v-if="option.remark">
          ({{option.remark}})
        </span>
      </small>
    </template>
  </sgv-input-autocomplete>
</template>

<script>
import { LIST_ORDER_PAYABLE } from './graph'

export default {
  name: 'DropdownOrderPayable',
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    select: {
      type: String,
      required: false
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
  },
  data () {
    return {
      display: null,
      options: [],
      search: '',
      isDefault: false
    }
  },
  methods: {
    getDisplay (option) {
      return `${option.code} (${option.name})`
    },
    emitSelected (option) {
      this.setDisplay(option)
      this.emitInput(this.optionByKey(option))
      this.emitChange(option)
    },
    setDisplay (option) {
      if (option) this.display = this.getDisplay(option)
    },
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    },
    emitInput (value) {
      this.$emit('input', value)
    },
    emitChange (value) {
      this.$emit('change', value)
    },
    refetch () {
      this.$apollo.query({
        query: LIST_ORDER_PAYABLE(this.templateType),
        variables: {
          docType: this.docType,
          q: {
            params: {
              contactId: this.contactId,
              search: this.search
            }
          }
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.options = res.data.payables
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
        this.emitChange(null)
      })
    },
    resetForm () {
      this.$refs.dropdown.resetForm()
    }
  },
  watch: {
    search () {
      this.refetch()
    }
  },
}
</script>

<style lang="css">
</style>
